import { FC } from 'react';
import './call-to-action-odt-block.scss';
import { CallToAtionOdtBlock } from '../../../../types/strapi/component/blocks/call-to-action-odt';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { referalApiWS } from '../../../../config/unsplash';
import { RichText } from '../../atoms/rich-text/rich-text';

interface CallToActionODTBlockProps {
  callToAtionBlock: CallToAtionOdtBlock;
}

const CallToActionOdtDisplayBlock: FC<CallToActionODTBlockProps> = ({
  callToAtionBlock,
}) => {
  return (
    <div
      className={`ppctaob-container${
        callToAtionBlock.isFullSize ? ' ppctaob-full-size-background' : ''
      }`}
    >
      <div className="ppctaob-size">
        <div className="ppctaob-layout">
          <div
            className={`ppctaob-text-layout${
              callToAtionBlock?.picto?.data ? ' ppctaob-text-width-with-image' : ''
            }`}
          >
            <div className="ago-principal-title-bold ppctaob-title">
              {callToAtionBlock.title}
            </div>
            {callToAtionBlock?.description && (
              <RichText
                text={callToAtionBlock?.description}
                className="ago-text-regular-16 ppctaob-description"
              />
            )}
            {(callToAtionBlock?.buttonUrl || callToAtionBlock?.buttonName) && (
              <NJButton
                href={callToAtionBlock?.buttonUrl ?? '/missing_link'}
                target={callToAtionBlock.openNewTab ? '_blank' : undefined}
                emphasis="subtle"
                variant="inverse"
                className="ppctaob-button"
              >
                <span data-child-name="customLabel" className="ppctaob-button-content">
                  {callToAtionBlock?.buttonIcon?.data && (
                    <img
                      className="ppctaob-button-icon"
                      src={`${referalApiWS}${callToAtionBlock?.buttonIcon?.data?.attributes.url}`}
                      alt="dl introduction pp"
                    />
                  )}
                  <div className="ppctaob-button-text">
                    {callToAtionBlock?.buttonName ?? ''}
                  </div>
                </span>
              </NJButton>
            )}
          </div>
          {callToAtionBlock?.picto?.data && (
            <div className="ppctaob-image-layout">
              <img
                className="ppctaob-image"
                src={`${referalApiWS}${callToAtionBlock?.picto?.data?.attributes.url}`}
                alt="dl introduction pp"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { CallToActionOdtDisplayBlock };
