import { FC, useEffect, useState } from 'react';
import './book-fullscreen-viewer.scss';
import { BookViewerContextType, useBookViewer } from './book-viewer-context';
import { NJBookPage } from './book-page';
import { IBookDimensions, IBookViewportSize } from './types/book-viewer-types';
import { useWindowsSizeBlock } from '../../../../utils/hooks/use-windows-size-block';
import { NJButton } from '@engie-group/fluid-design-system-react';

interface NJBookFullscreenViewerProps {}

const NJBookFullscreenViewerBlock: FC<NJBookFullscreenViewerProps> = () => {
  const { height } = useWindowsSizeBlock();
  const { pageFullscreen, setPageFullscreen } = useBookViewer() as BookViewerContextType;
  const [pageDimensions, setPageDimensions] = useState<IBookDimensions>({
    pageWidthREM: 0,
    pageHeightREM: 0,
  });

  const getFullscreenPageDimensions = (
    windowHeight: number,
    viewportSize: IBookViewportSize,
  ) => {
    const newDimensions: IBookDimensions = {
      pageWidthREM: 0,
      pageHeightREM: 0,
    };

    newDimensions.pageHeightREM = windowHeight / 16 - 7;
    newDimensions.pageWidthREM =
      (newDimensions.pageHeightREM / viewportSize.height) * viewportSize.width;

    return newDimensions;
  };

  useEffect(() => {
    if (pageFullscreen) {
      const pageViewportSize = pageFullscreen.viewportSize;
      const newPageDimensions = getFullscreenPageDimensions(height, pageViewportSize);

      setPageDimensions({ ...newPageDimensions });
    }
  }, [height]);

  return (
    <>
      {pageFullscreen && (
        <div className="book-fullscreen-viewer">
          <div className="book-fullscreen-viewer__close-container">
            <NJButton icon="close" onClick={() => setPageFullscreen(null)} />
          </div>
          <NJBookPage page={pageFullscreen} dimensions={pageDimensions} />
        </div>
      )}
    </>
  );
};

export { NJBookFullscreenViewerBlock };
