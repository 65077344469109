import React, { createContext, useContext, useState } from 'react';
import { IBookPage } from './types/book-viewer-types';

interface BookViewerContextType {
  pageNumber: number;
  setPageNumber: (newPageNumber: number) => void;
  pageFullscreen: IBookPage | null;
  setPageFullscreen: (newIsPageFullscreen: IBookPage | null) => void;
}

const BookViewerContext = createContext<BookViewerContextType | null>(null);

const useBookViewer = () => {
  return useContext(BookViewerContext);
};

const BookViewerProvider = ({ children }) => {
  const [pageNumber, setPageNumberContext] = useState(1);
  const [pageFullscreen, setPageFullscreenContext] = useState<IBookPage | null>(null);

  const setPageNumber = (newPageNumber: number) => {
    setPageNumberContext(newPageNumber);
  };

  const setPageFullscreen = (newPageFullscreen: IBookPage | null) => {
    setPageFullscreenContext(newPageFullscreen);
  };

  return (
    <BookViewerContext.Provider
      value={{ pageNumber, setPageNumber, pageFullscreen, setPageFullscreen }}
    >
      {children}
    </BookViewerContext.Provider>
  );
};

export { type BookViewerContextType, BookViewerProvider, useBookViewer };
