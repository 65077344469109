import { NJButton, TButtonProps } from '@engie-group/fluid-design-system-react';
import { FC, ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import "./rich-text.scss"

interface RichTextProps {
  text: string;
  className?: string;
}

const RichText: FC<RichTextProps> = ({ text, className }) => {
  const getFullSizeImageURL = () => {
    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = text;
    const columnElements = tmpElement.children;

    if (
      columnElements.length === 1 &&
      columnElements[0].children.length === 1 &&
      columnElements[0].children[0].nodeName === 'IMG'
    ) {
      const imgElement = columnElements[0].children[0] as HTMLImageElement;

      return imgElement.src;
    }
    return false;
  };
  const fullSizeImageURL = getFullSizeImageURL();

  return (
    <div className={`rich-text-custom${className? (" " + className) : ""}`}>
      {fullSizeImageURL ? (
        <div
          className="rich-text-custom__full-size-image"
          style={{
            background: `center / cover url(${fullSizeImageURL})`,
          }}
        />
      ) : (
        <ReactMarkdown
          children={text}
          rehypePlugins={[rehypeRaw]}
          components={{
            p(props) {
              const { node, children, ...rest } = props;
              const imgContainerElement = children as ReactElement;

              if (imgContainerElement.props?.node?.tagName === 'img') {
                return (
                  <p className="rich-text-custom__image-container">
                    {children}
                  </p>
                );
              }
              return <p {...rest}>{children}</p>;
            },
            img(props) {
              const { alt, src } = props;

              return <img alt={alt} src={src} />;
            },
            button(props: TButtonProps) {
              const { href, icon, children, variant, emphasis } = props;

              return (
                <>
                  {children ? (
                    <NJButton
                      href={href}
                      target="_blank"
                      icon={icon || undefined}
                      emphasis={emphasis || "subtle"}
                      variant={variant || "primary"}
                      label={children.toString()}
                    />
                  ) : undefined}
                </>
              );
            },
          }}
        />
      )}
    </div>
  );
};


export {RichText};