import { FC } from 'react';
import { getStrapiImage, IMG_FORMATS } from '../../../../../utils/image';
import { DataDomainBlock } from '../../../../../types/strapi/component/blocks/dataDomain';
import { NJIcon } from '@engie-group/fluid-design-system-react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './data-domain-block.scss';

interface DataDomainBlockProps {
  dataDomainBlock: DataDomainBlock;
  isSmallOnly?: boolean;
}

const DataDomainDisplayBlock: FC<DataDomainBlockProps> = ({ dataDomainBlock, isSmallOnly }) => {
  return (
    <div id={`data-domain-${dataDomainBlock.domain.data.id}`} className={`data-domain${
      isSmallOnly ? '' : ' data-domain-large-margin'
    }`}>
      <div
        className={`data-domain__image ${
          dataDomainBlock.domain.data.attributes.inverted &&
          'data-domain__image--inverted'
        }`}
        style={{
          background: `center / cover no-repeat url(${getStrapiImage(
            dataDomainBlock.domain.data.attributes.background.data?.attributes,
            IMG_FORMATS.full,
          )})`,
        }}
      />
      <div
        className={`data-domain__body ${
          dataDomainBlock.domain.data.attributes.inverted && 'data-domain__body--inverted'
        }`}
      >
        <div className="data-domain__body__container">
          <Markdown
            className="ppcdz-generic-markdown-link"
            {...{
              rehypePlugins: [rehypeRaw],
            }}
          >
            {dataDomainBlock.domain.data.attributes.description}
          </Markdown>
          {dataDomainBlock.domain.data.attributes.attachements?.data && (
            <div className="data-domain__body__container__attachements">
              {dataDomainBlock.domain.data.attributes.attachements.data.map(
                (attachement) => {
                  return (
                    <div
                      className="data-domain__body__container__attachements__item"
                      onClick={async () => {
                        const url = getStrapiImage(attachement.attributes);
                        const response = await fetch(url);
                        const blob = await response.blob();
                        const link = document.createElement('a');

                        link.href = URL.createObjectURL(blob);
                        link.download = attachement.attributes.name;
                        link.click();
                      }}
                    >
                      <NJIcon
                        className="data-domain__body__container__attachements__item__icon"
                        name="download"
                      />
                      <p>
                        {attachement.attributes.caption
                          ? attachement.attributes.caption
                          : attachement.attributes.name}
                      </p>
                    </div>
                  );
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DataDomainDisplayBlock };
