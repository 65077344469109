import { FC } from 'react';
import { ProgramAndProjectPagesResponse } from '../../../../types/strapi/program-pages/content-program-page/content-program-page';
import { ProgramPagesRouteInfo } from '../../../../types/routing';
import { ProgramMainPagesResponse } from '../../../../types/strapi/program-pages/program-pages';
import { ProgramPagesLayout } from './program-pages-layout';
import NotFound from '../../../../pages/NotFound/NotFound';

interface ProgramPagesValidatorProps {
  programPage: ProgramMainPagesResponse;
  pageContent: ProgramAndProjectPagesResponse;
  routeInfo: ProgramPagesRouteInfo;
}

const ProgramPagesValidator: FC<ProgramPagesValidatorProps> = ({
  programPage,
  pageContent,
  routeInfo,
}) => {
  return (
    <>
      {!!programPage.data.length ? (
        <ProgramPagesLayout
          programPage={programPage.data[0].attributes}
          pageContent={pageContent}
          routeInfo={routeInfo}
        />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export { ProgramPagesValidator };
