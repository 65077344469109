import { array, intersection, number, object, string } from 'idonttrustlikethat';
import { programPagesBlocks } from '../../component/blocks/program-pages-block';

const programAndProjectPage = intersection(
  object({
    slug: string,
    blocks: array(programPagesBlocks),
  }),
);

const programAndProjectPageResponse = object({
  id: number,
  attributes: programAndProjectPage,
});

const programAndProjectPagesResponse = object({
  data: array(programAndProjectPageResponse),
});

type ProgramAndProjectPagesResponse = typeof programAndProjectPagesResponse.T;
type ProgramAndProjectPageResponse = typeof programAndProjectPageResponse.T;
type ProgramAndProjectPage = typeof programAndProjectPage.T;

export {
  programAndProjectPagesResponse,
  programAndProjectPageResponse,
  programAndProjectPage,
};
export type {
  ProgramAndProjectPagesResponse,
  ProgramAndProjectPageResponse,
  ProgramAndProjectPage,
};
