import { FC } from 'react';
import './dowloadable-document-odt.scss';
import { DownloadableDoc } from '../../../../types/strapi/component/downloadable-doc-list/downloadable-doc-list';
import { NJButton, NJTag } from '@engie-group/fluid-design-system-react';
import { referalApiWS } from '../../../../config/unsplash';
import { RichText } from '../../atoms/rich-text/rich-text';
import { TBrandExtendedColorVariants } from '@engie-group/fluid-design-system-react/lib/global';
import { dateFormat } from '../../../../utils/date';
import { getStrapiImage } from '../../../../utils/image';

interface DowloadableDocumentODTProps {
  dlDocument: DownloadableDoc;
}

const DowloadableDocumentODTContent: FC<DowloadableDocumentODTProps> = ({
  dlDocument,
}) => {
  const tagDocTypeList: {
    type: string;
    color: TBrandExtendedColorVariants;
    icon: string;
  }[] = [
    {
      type: 'PDF',
      color: 'brand',
      icon: 'insert_drive_file',
    },
    {
      type: 'PPT',
      color: 'red',
      icon: 'insert_drive_file',
    },
    {
      type: 'Excel',
      color: 'green',
      icon: 'insert_drive_file',
    },
    {
      type: 'Teams',
      color: 'ultramarine',
      icon: 'group',
    },
    {
      type: 'Link',
      color: 'teal',
      icon: 'link',
    },
    {
      type: 'Podcast',
      color: 'purple',
      icon: 'rss_feed',
    },
  ];
  const tagDocType = tagDocTypeList.find((tag) => tag.type === dlDocument.documentType);

  return (
    <div className="ddocpp-document">
      <div className="ddocpp-document-layout">
        <div className="ddocpp-document-data-layout">
          <div className="ddocpp-document-title-and-tag-layout">
            {tagDocType && (
              <NJTag
                className="ddocpp-document-tag"
                label={tagDocType.type}
                iconName={tagDocType.icon}
                variant={tagDocType.color}
                size="sm"
              />
            )}
            <div className="ago-subtitle-h3-bold ddocpp-document-title">{dlDocument.title ?? ''}</div>
          </div>
          {dlDocument.localDocument.data?.attributes.createdAt && (
            <div className="ddocpp-document-date">
              {dateFormat(dlDocument.localDocument.data.attributes.createdAt)}
            </div>
          )}
        </div>

        <NJButton
          href={
            !dlDocument.localDocument.data
              ? dlDocument.urlExternalDocument ?? '/missing_link'
              : undefined
          }
          target={dlDocument.openNewTab ? '_blank' : undefined}
          emphasis="subtle"
          variant="secondary"
          onClick={
            !!dlDocument.localDocument?.data
              ? async () => {
                  if (dlDocument.localDocument?.data) {
                    const url = getStrapiImage(dlDocument.localDocument.data.attributes);
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const link = document.createElement('a');

                    link.href = URL.createObjectURL(blob);
                    link.download = dlDocument.localDocument.data.attributes.name;
                    link.click();
                  }
                }
              : undefined
          }
          className='ddocpp-document-button-layout'
        >
          <span data-child-name="customLabel" className="ddocpp-button-content">
            {dlDocument?.iconDownload?.data && (
              <img
                className="ddocpp-button-icon"
                src={`${referalApiWS}${dlDocument?.iconDownload?.data?.attributes.url}`}
                alt="dl introduction pp"
              />
            )}
            {dlDocument?.buttonName && <div className="ddocpp-button-text">{dlDocument?.buttonName}</div>}
          </span>
        </NJButton>
      </div>
      {dlDocument.description && (
        <RichText className="ddocpp-document-description" text={dlDocument.description} />
      )}
    </div>
  );
};

export { DowloadableDocumentODTContent };
