import { FC } from 'react';
import './indicators-odt-block.scss';
import { IndicatorsOdtBlock } from '../../../../types/strapi/component/blocks/indicators-odt';
import { IndicatorOdtContent } from '../../molecules/indicators-odt/indicators-odt-content';
import { RichText } from '../../atoms/rich-text/rich-text';

interface IndicatorsOdtBlockBlockProps {
  indicatorsBlock: IndicatorsOdtBlock;
  isSmallOnly?: boolean;
}

const IndicatorsOdtDisplayBlock: FC<IndicatorsOdtBlockBlockProps> = ({
  indicatorsBlock,
  isSmallOnly,
}) => {
  return (
    <div className="indicators_odt_container">
      {!!indicatorsBlock.title && (
        <h2 className="ago-sub-tera-title indicators_odt_title">
          {indicatorsBlock.title}
        </h2>
      )}
      <div
        className={`indicators_odt_layout${
          isSmallOnly ? ' indicators_odt_layout_small_only' : ''
        }`}
      >
        {indicatorsBlock.indicators_list.map((indicator, index) => (
          <IndicatorOdtContent
            key={index}
            indicatorValue={indicator.indicatorValue}
            indicatorName={indicator.indicatorName}
          />
        ))}
      </div>
      {indicatorsBlock?.description && (
        <RichText text={indicatorsBlock?.description} className="ago-text-regular-16 indicators_odt_rich-text" />
      )}
    </div>
  );
};

export { IndicatorsOdtDisplayBlock };
