import { FC } from 'react';
import { ProgramMainPage } from '../../../../types/strapi/program-pages/program-pages';
import { ProgramAndProjectPagesResponse } from '../../../../types/strapi/program-pages/content-program-page/content-program-page';
import { ProgramPagesRouteInfo } from '../../../../types/routing';
import { HeaderODTDisplayBlock } from '../../organisms/header-odt/blocks/header-odt-block';
import { ProgramAndProjectContentValidator } from './content-box/program-and-project-content-validator';
import './program-pages-layout.scss';
import { MenuProgramPages } from './navigation/menu-program-pages';
import { generateHeaderPath } from './utils/generate-header-path';

interface ProgramPagesLayoutProps {
  programPage: ProgramMainPage;
  pageContent: ProgramAndProjectPagesResponse;
  routeInfo: ProgramPagesRouteInfo;
}

const ProgramPagesLayout: FC<ProgramPagesLayoutProps> = ({
  programPage,
  pageContent,
  routeInfo,
}) => {
  return (
    <div className="page-program-full-size">
      <HeaderODTDisplayBlock
        headerODTBlock={programPage.header}
        pathList={generateHeaderPath(programPage.menu, routeInfo)}
        noAutoEndPath={true}
      />
      <div className="ppl-layout">
        <div className="ppl-navigation">
          <MenuProgramPages menu={programPage.menu} routeInfo={routeInfo} />
        </div>
        <div className="ppl-content">
          <ProgramAndProjectContentValidator pageContent={pageContent} />
        </div>
      </div>
    </div>
  );
};

export { ProgramPagesLayout };
