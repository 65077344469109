import { number, object, string } from 'idonttrustlikethat';

import { StrapiDataResponse, StrapiDatumResponse } from '../common/common-types';

const strapiImageFormat = object({
  ext: string,
  hash: string,
  height: number.nullable(),
  mime: string,
  name: string,
  size: number,
  url: string,
  width: number.nullable(),
});

const strapiFile = object({
  alternativeText: string.nullable(),
  caption: string.nullable(),
  ext: string,
  formats: object({
    large: strapiImageFormat.nullable(),
    medium: strapiImageFormat.nullable(),
    small: strapiImageFormat.nullable(),
    thumbnail: strapiImageFormat.nullable(),
  }).nullable(),
  hash: string,
  height: number.nullable(),
  mime: string,
  name: string,
  previewUrl: string.nullable(),
  size: number,
  url: string,
  width: number.nullable(),
  updatedAt: string.nullable(),
  createdAt: string.nullable()
});

const strapiFileAttributes = object({
  id: number,
  attributes: strapiFile,
});

const strapiFileResponse = object({ data: strapiFileAttributes });
const strapiNullableFileResponse = object({ data: strapiFileAttributes.nullable() });

export {
  strapiFile,
  strapiFileAttributes,
  strapiFileResponse,
  strapiNullableFileResponse,
};

export type StrapiFile = {
  alternativeText: string;
  caption: string;
  ext: string;
  formats: Object;
  hash: string;
  height: number;
  mime: string;
  name: string;
  previewUrl: string;
  size: number;
  url: string;
  width: number;
};

export type StrapiSimpleLinkedFile = StrapiDatumResponse<StrapiFile>;
export type StrapiLinkedFile = StrapiDataResponse<StrapiFile>;

export type IDTLTStrapiFile = typeof strapiFile.T;
