import React, { FC, ReactElement } from 'react';
import { TwoColumnBlock } from '../../../../../types/strapi/component/blocks/two-column-block';
import { NJButton, TButtonProps } from '@engie-group/fluid-design-system-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './two-column-block.scss';

interface ColumnProps {
  columnContent: string;
}

const Column: FC<ColumnProps> = ({ columnContent }) => {
  // Below function is used to know if picture need to fill entire column space
  const getFullSizeImageURL = () => {
    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = columnContent;
    const columnElements = tmpElement.children;

    if (
      columnElements.length === 1 &&
      columnElements[0].children.length === 1 &&
      columnElements[0].children[0].nodeName === 'IMG'
    ) {
      const imgElement = columnElements[0].children[0] as HTMLImageElement;

      return imgElement.src;
    }
    return false;
  };
  const fullSizeImageURL = getFullSizeImageURL();

  return (
    <div className="two-column__container__column">
      {fullSizeImageURL ? (
        <div
          className="two-column__container__column__full-size-image"
          style={{
            background: `center / cover url(${fullSizeImageURL})`,
          }}
        />
      ) : (
        <ReactMarkdown
          children={columnContent}
          rehypePlugins={[rehypeRaw]}
          components={{
            p(props) {
              const { node, children, ...rest } = props;
              const imgContainerElement = children as ReactElement;

              // Below code is used to remove a small space behind image
              if (imgContainerElement.props?.node?.tagName === 'img') {
                return (
                  <p className="two-column__container__column__image-container">
                    {children}
                  </p>
                );
              }
              return <p {...rest}>{children}</p>;
            },
            img(props) {
              const { alt, src } = props;

              return <img alt={alt} src={src} />;
            },
            button(props: TButtonProps) {
              const { href, icon, children } = props;

              return (
                <>
                  {children ? (
                    <NJButton
                      href={href}
                      target="_blank"
                      icon={icon || 'arrow_forward'}
                      emphasis="subtle"
                      label={children.toString()}
                    />
                  ) : undefined}
                </>
              );
            },
          }}
        />
      )}
    </div>
  );
};

interface TwoColumnBlockProps {
  twoColumnBlock: TwoColumnBlock;
}

const TwoColumnDisplayBlock: FC<TwoColumnBlockProps> = ({ twoColumnBlock }) => {
  return (
    <div className="two-column">
      {twoColumnBlock.title && <h1>{twoColumnBlock.title}</h1>}
      <div className="two-column__container">
        <Column columnContent={twoColumnBlock.first_column} />
        <Column columnContent={twoColumnBlock.second_column} />
      </div>
    </div>
  );
};

export { TwoColumnDisplayBlock };
