import { FC } from 'react';
import './introduction-odt-pp-block.scss';
import { referalApiWS } from '../../../../config/unsplash';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { IntroductionODTPPBlock } from '../../../../types/strapi/component/blocks/introduction-odt-pp';
import { RichText } from '../../atoms/rich-text/rich-text';

interface IntroductionODTPPBlockProps {
  introductionODTPPBlock: IntroductionODTPPBlock;
}

const IntroductionODTPPDisplayBlock: FC<IntroductionODTPPBlockProps> = ({
  introductionODTPPBlock,
}) => {
  return (
    <div className="ippb-layout">
      <div
        className={`ippb-box ${
          introductionODTPPBlock?.image?.data
            ? 'ippb-box-image-bottom-margin'
            : 'ippb-box-no-image-margin'
        }`}
      >
        <div className="ippb-text-layout">
          <h2 className="ippb-title">{introductionODTPPBlock.title}</h2>
          <RichText
            text={introductionODTPPBlock?.description ?? ''}
            className="ago-text-regular-16 ippb-description"
          />
          {(introductionODTPPBlock?.urlDownload ||
            introductionODTPPBlock?.descriptionDownload) && (
            <div className="ippb-button-layout">
              <NJButton
                href={introductionODTPPBlock?.urlDownload ?? '/missing_link'}
                target={introductionODTPPBlock.downloadOpenNewTab ? '_blank' : undefined}
                emphasis="subtle"
                variant="secondary"
                className="ippb-button"
              >
                <span data-child-name="customLabel" className="ippb-button-content">
                  {introductionODTPPBlock?.iconDownload?.data && (
                    <img
                      className="ippd-button-icon"
                      src={`${referalApiWS}${introductionODTPPBlock?.iconDownload?.data?.attributes.url}`}
                      alt="dl introduction pp"
                    />
                  )}
                  {introductionODTPPBlock?.descriptionDownload ?? 'Download'}
                </span>
              </NJButton>
            </div>
          )}
        </div>
      </div>
      {introductionODTPPBlock?.image?.data && (
        <img
          className="ippb-image"
          src={`${referalApiWS}${introductionODTPPBlock?.image?.data?.attributes.url}`}
          alt="introduction pp"
        />
      )}
    </div>
  );
};

export { IntroductionODTPPDisplayBlock };
