import { string, object, boolean, array } from 'idonttrustlikethat';
import { strapiNullableFileResponse } from '../../file/strapi-file';

const downloadableDoc = object({
  title: string.nullable(),
  description: string.nullable(),
  localDocument: strapiNullableFileResponse,
  urlExternalDocument: string.nullable(),
  iconDownload: strapiNullableFileResponse,
  openNewTab: boolean.nullable(),
  buttonName: string.nullable(),
  documentType: string.nullable()
});

const downloadableDocs = array(downloadableDoc);

type DownloadableDoc = typeof downloadableDoc.T;
type DownloadableDocs = typeof downloadableDocs.T;

export { downloadableDoc, downloadableDocs };
export type { DownloadableDoc, DownloadableDocs };
