import { FC, useEffect, useState } from 'react';
import { MenuProgramPageResponse } from '../../../../../types/strapi/program-pages/menu-program-page/menu-program-page';
import './menu-program-pages.scss'
import { MenuProgramPagesList } from './menu-program-pages-list';
import { MenuProgramPagesSelector } from './menu-program-pages-selector';
import { ProgramPagesRouteInfo } from '../../../../../types/routing';

interface MenuProgramPagesProps {
  menu: MenuProgramPageResponse;
  routeInfo: ProgramPagesRouteInfo;
}

const MenuProgramPages: FC<MenuProgramPagesProps> = ({ menu, routeInfo }) => {
  const menuData = menu.data?.attributes;
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const header = document.getElementById('header_one_data_team')
    const headerHeight = header?.offsetHeight;
    const milestone = window.scrollY
    if (headerHeight && milestone > headerHeight) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {menuData ? (
        <div>
          <div className={`mpp-display-List ${isSticky ? 'mpp-sticky' : ''}`}>
            <MenuProgramPagesList menu={menuData} routeInfo={routeInfo} />
          </div>
          <div className="mpp-display-Selector">
            <MenuProgramPagesSelector
              menu={menuData}
              routeInfo={routeInfo}
              isSticky={isSticky}
            />
          </div>
        </div>
      ) : (
        <div className="mt-32 text-center h-full">
          <p className="text-2xl mb-6">Ooops... menu not found</p>
        </div>
      )}
    </>
  );
};

export { MenuProgramPages };
