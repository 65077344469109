import { array, literal, number, object, string } from 'idonttrustlikethat';
import { fluidButton } from '../buttonComponents/fluid-button';

const ctaComponent = literal('blocks.cta');
const ctaBlock = object({
  id: number,
  __component: ctaComponent,
  title: string,
  description: string,
  actions: array(fluidButton).default([]),
});

type CtaBlock = typeof ctaBlock.T;
type CtaComponent = typeof ctaComponent.T;

export { ctaBlock, ctaComponent };
export type { CtaBlock, CtaComponent };
