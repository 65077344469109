import { ProgramPagesRouteInfo } from "../../../../../types/routing";
import { MenuProgramPageResponse } from "../../../../../types/strapi/program-pages/menu-program-page/menu-program-page";

export const generateHeaderPath = (
  menu: MenuProgramPageResponse,
  routeInfo: ProgramPagesRouteInfo,
): {
  url: string;
  label: string;
}[] => {
  const res = [{ url: '/onedatateam', label: 'One Data Team' }];
  const menuData = menu.data?.attributes
  if (!menuData)
    return res
  const currentProject = menuData.projects.find(
    (project) => project.projectSlug === routeInfo.params.project_slug,
  );
  if (currentProject) {
    res.push({
      url: '/onedatateam/' + menuData.programSlug,
      label: menuData.programMenuName,
    });
    const currentsubProject = currentProject.subProjects.find(
      (subProject) => subProject.subProjectSlug === routeInfo.params.subproject_slug,
    );
    if (currentsubProject) {
      res.push({
        url: currentProject.projectSlug + '/',
        label: `${currentProject.projectMenuName}`,
      });
      res.push({
        url: '',
        label: `${currentsubProject.subProjectMenuName}`,
      });
    } else {
      res.push({ url: '', label: currentProject.projectMenuName });
    }
  } else {
    res.push({ url: '', label: menuData.programMenuName });
  }
  return res;
};
