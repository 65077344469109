import { literal, number, object, string } from 'idonttrustlikethat';
import { downloadableDocs } from '../downloadable-doc-list/downloadable-doc-list';

const downloadableDocumentODTComponent = literal('blocks.downloadable-doc-odt');
const downloadableDocumentODTBlock = object({
  id: number,
  __component: downloadableDocumentODTComponent,
  title: string.nullable(),
  documents: downloadableDocs.nullable(),
});

type DownloadableDocumentODTBlock = typeof downloadableDocumentODTBlock.T;
type DownloadableDocumentODTComponent = typeof downloadableDocumentODTComponent.T;

export { downloadableDocumentODTBlock, downloadableDocumentODTComponent };
export type { DownloadableDocumentODTBlock, DownloadableDocumentODTComponent };
