import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './key-people-block.scss';
import { KeyPeopleBlock } from '../../../../types/strapi/component/blocks/key-people';
import { KeyPeopleContact } from '../../molecules/key-people-contact/key-people-contact';

interface KeyPeopleBlockProps {
  keyPeopleBlock: KeyPeopleBlock;
}

const KeyPeopleDisplayBlock: FC<KeyPeopleBlockProps> = ({ keyPeopleBlock }) => {
  return (
    <div className="key-people">
      {keyPeopleBlock.title && (
        <h1 className="key-people__title ago-sub-tera-title">{keyPeopleBlock.title}</h1>
      )}
      {keyPeopleBlock.sections.map((section, index) => (
        <div className="key-people__section">
          <div className="key-people__section__description">
            <ReactMarkdown children={section.description} rehypePlugins={[rehypeRaw]} />
          </div>
          <div className="key-people__section__list">
            {section.peoples.data?.map((contact) => (
              <KeyPeopleContact key={contact.id} contact={contact} />
            ))}
          </div>
          <div
            className="key-people__section__separator"
            style={{
              display: index + 1 === keyPeopleBlock.sections.length ? 'none' : undefined,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export { KeyPeopleDisplayBlock };
