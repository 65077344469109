import { FC } from 'react';
import './cta-block.scss'
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import { FluidButton } from '../../../../types/strapi/component/buttonComponents/fluid-button';
import { NJButton } from '@engie-group/fluid-design-system-react';

interface CTABlockProps {
    actions?: FluidButton[];
    description: string;
    title: string;
}

const CTABlock: FC<CTABlockProps> = ({ actions = [], description, title }) => {
    return (
        <div className="ppcta-container ppcta-size">
            <div className="ppcta-title">
                {title}
            </div>
            <ReactMarkdown
                className={'ago-text-regular-16'}
                children={description?.replace(/\n/g, '<br />')}
                rehypePlugins={[rehypeRaw]}
            />
            {actions?.length > 0 && <div className='ppcta-btns'>
                {actions.map((btn) => <NJButton {...{
                    ...btn, icon: btn.icon_name, size: "medium"
                }} />)}
            </div>}
        </div>
    );
};

export { CTABlock };
