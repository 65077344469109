import { FC } from 'react';
import './book-page.scss';
import { IBookDimensions, IBookPage } from './types/book-viewer-types';
import { NJBookPageAnnotation } from './book-page-annotation';
import { BookViewerContextType, useBookViewer } from './book-viewer-context';

interface NJBookPageProps {
  page: IBookPage;
  dimensions: IBookDimensions;
}

const NJBookPage: FC<NJBookPageProps> = ({ page, dimensions }) => {
  const { pageFullscreen, setPageFullscreen } = useBookViewer() as BookViewerContextType;

  const pageFullscreenHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = e.target as HTMLElement;

    if (pageFullscreen === null && element.nodeName === 'DIV') {
      setPageFullscreen(page);
    } else if (pageFullscreen && element.nodeName === 'DIV') {
      setPageFullscreen(null);
    }
  };

  return (
    <div id={`book-page-${page.id}`} className="book-page">
      <div
        className="book-page__container"
        style={{ cursor: pageFullscreen ? 'zoom-out' : 'zoom-in' }}
        onClick={pageFullscreenHandler}
      >
        <img
          alt={`Engie book page ${page.id}`}
          src={page.image}
          style={{
            width: `${dimensions.pageWidthREM}rem`,
            height: `${dimensions.pageHeightREM}rem`,
          }}
        />
        {page.annotations &&
          page.annotations.map((annotation) => (
            <NJBookPageAnnotation
              page={page}
              dimensions={dimensions}
              annotation={annotation}
            />
          ))}
      </div>
    </div>
  );
};

export { NJBookPage };
