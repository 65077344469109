import { array, number, object, string } from 'idonttrustlikethat';

const menuSubProject = object({
  subProjectMenuName: string,
  subProjectSlug: string,
});

const menuSubProjects = array(menuSubProject);

const menuProject = object({
  projectMenuName: string,
  projectSlug: string,
  subProjects: menuSubProjects,
});

const menuProjects = array(menuProject);

const menuProgramPage = object({
  programMenuName: string,
  programSlug: string,
  projects: menuProjects,
});

const menuProgramPageResponse = object({
  data: object({
    id: number,
    attributes: menuProgramPage,
  }).nullable(),
});

type MenuProgramPageResponse = typeof menuProgramPageResponse.T;
type MenuProgramPage = typeof menuProgramPage.T;
type MenuProjects = typeof menuProjects.T;
type MenuProject = typeof menuProject.T;
type MenuSubProjects = typeof menuSubProjects.T;
type MenuSubProject = typeof menuSubProject.T;

export {
  menuProgramPageResponse,
  menuProgramPage,
  menuProjects,
  menuProject,
  menuSubProjects,
  menuSubProject,
};
export type {
  MenuProgramPageResponse,
  MenuProgramPage,
  MenuProjects,
  MenuProject,
  MenuSubProjects,
  MenuSubProject,
};
