import { literal, object, string, union } from "idonttrustlikethat";

const buttonVariants = union(
    literal("destructive"),
    literal("inverse"),
    literal("primary"),
    literal("secondary")
)

const fluidButton = object({
    href: string,
    icon_name: string,
    label: string,
    variant: buttonVariants
})

type FluidButton = typeof fluidButton.T
export { fluidButton }
export type { FluidButton }
