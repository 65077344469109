import { FC } from 'react';
import { VerticalStepperBlock } from '../../../../types/strapi/component/blocks/vertical-stepper';
import './vertical-stepper.scss';
import { getStrapiImage } from '../../../../utils/image';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { VerticalStepperSteps } from './vertical-stepper-steps';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface VerticalStepperBlockProps {
  verticalStepperBlock: VerticalStepperBlock;
}

const VerticalStepperDisplayBlock: FC<VerticalStepperBlockProps> = ({
  verticalStepperBlock,
}) => {
  const imageURL =
    verticalStepperBlock.image?.data?.attributes &&
    getStrapiImage(verticalStepperBlock.image.data.attributes, 1);

  return (
    <div className="vertical-stepper">
      <h1 className="vertical-stepper__title">{verticalStepperBlock.title}</h1>
      {verticalStepperBlock.description && (
        <ReactMarkdown
          className="vertical-stepper__description"
          children={verticalStepperBlock.description}
          rehypePlugins={[rehypeRaw]}
        />
      )}
      {imageURL && <img alt="vertical-stepper" src={imageURL} />}
      <VerticalStepperSteps steps={verticalStepperBlock.steps} />
      {verticalStepperBlock.button && (
        <NJButton
          className="vertical-stepper__button"
          emphasis="subtle"
          size="medium"
          icon="open_in_new"
          label={verticalStepperBlock.button.label}
          href={verticalStepperBlock.button.link}
          target="_blank"
        />
      )}
    </div>
  );
};

export { VerticalStepperDisplayBlock };
