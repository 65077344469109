import React, { useEffect, useState } from 'react';
import { IBookDimensions } from '../types/book-viewer-types';
import { BookViewerContextType, useBookViewer } from '../book-viewer-context';

const useBookPageDimensions = (
  defaultState: IBookDimensions,
): [IBookDimensions, React.Dispatch<React.SetStateAction<IBookDimensions>>] => {
  const { pageNumber } = useBookViewer() as BookViewerContextType;
  const [bookPageDimensions, setBookPageDimensions] =
    useState<IBookDimensions>(defaultState);

  useEffect(() => {
    const container = document.getElementById(`book-page-${pageNumber}`);

    if (container) {
      container.scrollIntoView({ block: 'center' });
    }
  }, [bookPageDimensions]);

  return [bookPageDimensions, setBookPageDimensions];
};

export { useBookPageDimensions };
