import { FC, useEffect, useState } from 'react';
import { NJSpinner } from '@engie-group/fluid-design-system-react';
import { BookPDFViewerBlock } from '../../../../types/strapi/component/blocks/book-pdf-viewer';
import { getStrapiImage } from '../../../../utils/image';
import './book-viewer-block.scss';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { BookViewerContextType, useBookViewer } from './book-viewer-context';
import { NJBook } from './book';
import { IBookPage } from './types/book-viewer-types';
import { NJBookFullscreenViewerBlock } from './book-fullscreen-viewer';

interface BookViewerBlockProps {
  bookPDFViewerBlock: BookPDFViewerBlock;
}

const BookViewerDisplayBlock: FC<BookViewerBlockProps> = ({ bookPDFViewerBlock }) => {
  const { pageNumber, setPageNumber, pageFullscreen } =
    useBookViewer() as BookViewerContextType;
  const [bookFile, setBookFile] = useState<IBookPage[] | null>(null);

  const getBookFile = async () => {
    const bookURL = getStrapiImage(bookPDFViewerBlock.file.data.attributes);
    const response = await fetch(bookURL);
    const body = await response.json();

    setBookFile(body);
  };

  const nextPage = () => {
    let newPageNumber = 1;

    if (bookFile && pageNumber + 2 <= bookFile.length) {
      newPageNumber = pageNumber + 2;
    } else if (bookFile && pageNumber + 1 <= bookFile.length) {
      newPageNumber = pageNumber + 1;
    } else {
      return;
    }

    const container = document.getElementById(`book-page-${newPageNumber}`);

    if (container) {
      container.scrollIntoView({ block: 'center' });
      setPageNumber(newPageNumber);
    }
  };

  const prevPage = () => {
    let newPageNumber = 1;

    if (bookFile && pageNumber - 2 >= 1) {
      newPageNumber = pageNumber - 2;
    } else if (bookFile && pageNumber - 1 >= 1) {
      newPageNumber = pageNumber - 1;
    } else {
      return;
    }

    const container = document.getElementById(`book-page-${newPageNumber}`);

    if (container) {
      container.scrollIntoView({ block: 'center' });
      setPageNumber(newPageNumber);
    }
  };

  useEffect(() => {
    getBookFile();
  }, []);

  return (
    <>
      {pageFullscreen && <NJBookFullscreenViewerBlock />}
      {bookFile ? (
        <div className="book-viewer">
          {pageNumber > 1 && (
            <NJButton
              icon="chevron_left"
              className="book-viewer__button"
              onClick={() => prevPage()}
            />
          )}
          <NJBook bookFile={bookFile} />
          {pageNumber < bookFile.length && (
            <NJButton
              icon="chevron_right"
              className="book-viewer__button"
              onClick={() => nextPage()}
            />
          )}
        </div>
      ) : (
        <div className="book-viewer-spinner">
          <NJSpinner isLoading size="md" />
        </div>
      )}
    </>
  );
};

export { BookViewerDisplayBlock };
