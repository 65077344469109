import { FC, useEffect } from 'react';
import './book.scss';
import { NJBookPage } from './book-page';
import { IBookDimensions, IBookPage, IBookViewportSize } from './types/book-viewer-types';
import { useWindowsSizeBlock } from '../../../../utils/hooks/use-windows-size-block';
import { useBookPageDimensions } from './hooks/use-book-page-dimensions';

interface NJBookProps {
  bookFile: IBookPage[];
}

const NJBook: FC<NJBookProps> = ({ bookFile }) => {
  const [bookPageDimensions, setBookPageDimensions] = useBookPageDimensions({
    pageWidthREM: 0,
    pageHeightREM: 0,
  });
  const { width } = useWindowsSizeBlock();

  const getPageDimensions = (windowWidth: number, viewportSize: IBookViewportSize) => {
    const newDimensions: IBookDimensions = {
      pageWidthREM: 0,
      pageHeightREM: 0,
    };

    if (windowWidth >= 1600) {
      newDimensions.pageWidthREM = 43.75;
    } else if (windowWidth >= 1300) {
      newDimensions.pageWidthREM = 34.375;
    } else if (windowWidth >= 900) {
      newDimensions.pageWidthREM = 21.875;
    } else if (windowWidth >= 768) {
      newDimensions.pageWidthREM = 18.75;
    } else {
      newDimensions.pageWidthREM = 6.25;
    }
    newDimensions.pageHeightREM =
      (newDimensions.pageWidthREM / viewportSize.width) * viewportSize.height;

    return newDimensions;
  };

  useEffect(() => {
    if (bookFile.length > 0) {
      const pageViewportSize = bookFile[0].viewportSize;
      const newPageDimensions = getPageDimensions(width, pageViewportSize);

      setBookPageDimensions({ ...newPageDimensions });
    }
  }, [width]);

  return (
    <div
      className="book-container"
      style={{
        height: `${bookPageDimensions.pageHeightREM}rem`,
      }}
    >
      {bookFile.map((page) => {
        return <NJBookPage page={page} dimensions={bookPageDimensions} />;
      })}
    </div>
  );
};

export { NJBook };
