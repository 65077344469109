import { literal, number, object, string, array } from 'idonttrustlikethat';
import { contactsResponse } from '../../contact/contact';

const keyPeopleComponent = literal('blocks.key-people');
const keyPeopleBlock = object({
  id: number,
  __component: keyPeopleComponent,
  title: string.nullable(),
  sections: array(
    object({
      id: number,
      description: string.nullable(),
      peoples: contactsResponse,
    }),
  ),
});

type KeyPeopleBlock = typeof keyPeopleBlock.T;
type KeyPeopleComponent = typeof keyPeopleComponent.T;

export { keyPeopleBlock, keyPeopleComponent };
export type { KeyPeopleBlock, KeyPeopleComponent };
