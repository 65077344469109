import { array, number, object, string } from 'idonttrustlikethat';
import { headerODTSingleBlock } from '../component/blocks/header-odt';
import { menuProgramPageResponse } from './menu-program-page/menu-program-page';

const programMainPage = object({
  slug: string,
  menu: menuProgramPageResponse,
  header: headerODTSingleBlock,
});

const programMainPageResponse = object({
  id: number,
  attributes: programMainPage,
});

const programMainPagesResponse = object({
  data: array(programMainPageResponse),
});

type ProgramMainPagesResponse = typeof programMainPagesResponse.T;
type ProgramMainPageResponse = typeof programMainPageResponse.T;
type ProgramMainPage = typeof programMainPage.T;

export { programMainPagesResponse, programMainPageResponse, programMainPage };
export type { ProgramMainPagesResponse, ProgramMainPageResponse, ProgramMainPage };
