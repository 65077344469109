import { FC } from 'react';
import { getStrapiImage } from '../../../../../utils/image';
import { DomainsListBlock } from '../../../../../types/strapi/component/blocks/domainsList';
import './domains-list-block.scss';
import { NJCardCover } from '@engie-group/fluid-design-system-react';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';

interface DomainsListBlockProps {
  domainsListBlock: DomainsListBlock;
  isSmallOnly?: boolean;
}

const DomainsListDisplayBlock: FC<DomainsListBlockProps> = ({ domainsListBlock, isSmallOnly }) => {
  return (
    <div
      className={`data-domains-list${
        isSmallOnly ? '' : ' data-domains-list-large-margin'
      }`}
    >
      <div className="data-domains-list__header">
        <h1>{domainsListBlock.title}</h1>
        <ReactMarkdown
          className={'ago-text-regular-16 ppcdz-generic-markdown-link'}
          children={domainsListBlock?.description?.replace(/\n/g, '<br />')}
          rehypePlugins={[rehypeRaw]}
        />
      </div>
      <div className="data-domains-list__body">
        <div className="data-domains-list__body__domains-list">
          {domainsListBlock.domains.data.map((domain) => (
            <NJCardCover
              className="data-domains-list__body__domains-list__domain"
              background={getStrapiImage(domain.attributes.background.data?.attributes)}
              heading={domain.attributes.title}
              onClick={() => {
                const card = document.getElementById(`data-domain-${domain.id}`);

                if (card) {
                  card.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
              }}
              href=""
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { DomainsListDisplayBlock };
