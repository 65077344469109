import { FC } from 'react';
import './downloadable-document-odt-block.scss';
import { DownloadableDocumentODTBlock } from '../../../../types/strapi/component/blocks/downloadable-document-odt';
import {
  NJAccordion,
  NJAccordionItem,
  NJAccordionItemContent,
  NJAccordionItemHeader,
} from '@engie-group/fluid-design-system-react';
import { DowloadableDocumentODTContent } from '../../molecules/dowloadable-document-odt/dowloadable-document-odt';

interface DownloadableDocumentsODTBlockProps {
  documentsBlock: DownloadableDocumentODTBlock;
}

const DownloadableDocumentsODTDisplayBlock: FC<DownloadableDocumentsODTBlockProps> = ({
  documentsBlock,
}) => {
  return (
    <div className="ddopp-container">
      <div className="ddopp-layout">
        <NJAccordion className="ddopp-accordion">
          <NJAccordionItem>
            <NJAccordionItemHeader className="ddopp-header">
              {documentsBlock.title ?? 'Additional resources'}
            </NJAccordionItemHeader>
            <NJAccordionItemContent className="ddopp-accordion-content">
              {documentsBlock.documents ? (
                documentsBlock.documents.map((document) => (
                  <DowloadableDocumentODTContent dlDocument={document} />
                ))
              ) : (
                <div>Aucune ressource disponible</div>
              )}
            </NJAccordionItemContent>
          </NJAccordionItem>
        </NJAccordion>
      </div>
    </div>
  );
};

export { DownloadableDocumentsODTDisplayBlock };
